@import 'Styles/includes';

.Root {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
    color: $text;
    border-radius: 4px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(black, 0.15);
    transition: box-shadow 0.15s ease;

    @include media(sm) {
        min-height: 260px;
    }

    &:hover {
        box-shadow: 0 0 6px 0 rgba(black, 0.3);

        @media (hover: none) {
            box-shadow: 0 0 4px 0 rgba(black, 0.15);
        }
    }

    &--BgHex,
    &--WhiteText {
        color: white;
    }

    &--BlackText {
        color: $text !important;
    }

    // Adds background color, must be placed after above
    @include sensus-bg-modifiers();

    &--NoImage {
        justify-content: center;
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;
    }

    &__Image {
        position: relative;
        height: 200px;
        width: 100%;
        border-radius: 4px 4px 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(black, .4);
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        color: inherit;
        padding: 16px;

        @include media(md) {
            padding: 24px;
        }
    }

    &__Label {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 8px;
        letter-spacing: .5px;

        @include media(md) {
            font-size: 1.4rem;
        }
    }

    &__Title {
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.33;
        margin-bottom: 8px;
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        padding-right: 8px;
        overflow: hidden;
    }

    &__Arrow {
        &::after {
            content: url('#{$publicpath}img/icon-arrow-right.svg');
            transition: left 0.25s ease;
            display: inline-block;
            position: relative;
            top: 3px;
            left: 8px;
            width: 15px;
    
            #{$root}--BgHex &,
            #{$root}--WhiteText & {
                content: url('#{$publicpath}img/icon-arrow-right--white.svg');
            }
    
            #{$root}--BlackText & {
                content: url('#{$publicpath}img/icon-arrow-right.svg') !important;
            }
        }
    
        #{$root}:hover & {
            &::after {
                left: 12px;
            }
        }
    }

    &__ArrowWrapper {
        margin-top: 16px;
        font-size: 1.3rem;
        font-weight: bold;
        align-items: baseline;
        display: flex;
    }
}
